#sideSelectionIndicator, .requiredBlip {
  background-color: var(--accent-color);
  border-color: var(--accent-border-color);
}

.requiredBlip {
  -webkit-box-shadow: inset 0 1px 1px var(--accent-highlight-color);
  -moz-box-shadow: inset 0 1px 1px var(--accent-highlight-color);
  -o-box-shadow: inset 0 1px 1px var(--accent-highlight-color);
  box-shadow: inset 0 1px 1px var(--accent-highlight-color);
}

.noteBorder-default, .noteBorder-background {
  border-left-color: var(--banner-color);
}

#topArea {
  background-image: none;
  background-color: var(--banner-color);
}

.noteBorder-default, .noteButton-title {
  border-left-color: var(--accent-text-color);
}

.lineTitle, .bigTitle, .largeTitle, .largeTitle .titleLink, .largeTitle .titleNumber, .largeTitle .titleTitle, .noteInfoLabel, .content .titleLink:hover, #resultQuery, .docEditLink, .accountTitleLink, .accountInfoTitle, .dashboardTitle, #pageTitle, .pageSubTitle, .pageSubTitle a, #resultRequeryInput, article h1, body.contentPage div.toc.title:not(.smallParent) .link span, .lineTitle span {
  color: var(--accent-text-color);
}

.customerTitleColor, .noJsPaperTitle, .customerTitleColor .titleNumber, .customerTitleColor .titleTitle {
  color: var(--accent-text-color) !important;
}

.noteBorder-default, .noteBorder-accent {
  border-left-color: var(--accent-color);
}
.customerAccentColor, .customerAccentColor_Hover:hover, #analysisProgress::-webkit-progress-value {
  color: var(--accent-color) !important;
}
.customerAccentColor_Background, .customerAccentColor_Background_Hover:hover {
  background-color: var(--accent-color) !important;
}
.customerAccentColor_Border, .customerAccentColor_Border_Hover:hover {
  border-color: var(--accent-color) !important;
}

.lineTitleLine {
  border-bottom-color: var(--accent-line-color);
}

#muniName, #countyName {
  color: var(--banner-color-contrast);
}